<template>
  <v-card>
    <v-card-title>
      Zpětná vazba
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="feedback"
      :loading="runningRequest"
      :options.sync="paginationOptions"
      :server-items-length="feedbackTotal"
      item-key="id"
      :footer-props="{'items-per-page-options': [10, 20, 50] }"
    >
      <template v-slot:item.rating="{ item }">
        <v-rating
          color="orange"
          readonly
          :value="item.rating"
          half-increments
          length="3"
          size="22"
        >
        </v-rating>
      </template>
      <template v-slot:item.reporter.name="{ item }"
      >
        {{ item.reporter.email }}
        <!-- vypise jmeno a prijmeni, pokud je ma, jinak mail-->
        <!-- {{item.reporter.firstName && item.reporter.lastName ? `${item.reporter.firstName} ${item.reporter.lastName}` : item.reporter.email}} -->
      </template>
      <template v-slot:item.reported.name="{ item }"
      >
        {{ item.reported.email }}
        <!-- {{item.reported.firstName && item.reported.lastName ? `${item.reported.firstName} ${item.reported.lastName}` : item.reported.email}} -->
      </template>
      <template v-slot:item.actions="{ /*item*/ }">
        <v-icon
          small
          @click="deleteDialog = true"
          class="mr-2"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          @click="deleteFeedback(item)"
          class="mr-2"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="deleteDialog">
      <DeleteResource
        v-if="deleteDialog"
        :resource="feedbackToDelete"
        resource-type="admin/feedback"
        @delete-success="showDeleteSuccess(); getFeedback()"
        @delete-failure="showDeleteFailure"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import archiveHelpers from '@/mixins/local/archiveHelpers'
import DeleteResource from '@/components/DeleteResource'

export default {
  name: 'Feedback',
  components: { DeleteResource },
  mixins: [archiveHelpers],
  data () {
    return {
      runningRequest: false,
      headers: [
        {
          text: 'Nahlašovatel',
          value: 'reporter.name'
        },
        {
          text: 'Nahlášený',
          value: 'reported.name'
        },
        {
          text: 'Poptávka/Rezervace',
          value: 'demandId'
        },
        {
          text: 'Hodnocení',
          value: 'rating'
        },
        {
          text: 'Popis',
          value: 'text'
        },
        {
          text: 'Akce',
          value: 'actions',
          sortable: false
        }
      ],
      feedback: [],
      feedbackTotal: 0,
      paginationOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: []
      },
      feedbackToDelete: null
    }
  },
  methods: {
    loadFeedback () {},
    getFeedback () {
      this.deleteDialog = false
      this.runningRequest = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.paginationOptions

      this.$http.get('/admin/feedback', {
        params: {
          sortBy: sortBy.length ? sortBy[0] : null,
          sortDesc: sortDesc.length ? sortDesc[0] : null,
          page,
          itemsPerPage
        }
      })
        .then(res => {
          this.feedback = res.data.feedback
          this.feedbackTotal = +res.data.total
        })
        .finally(() => {
          this.runningRequest = false
        })
    },
    deleteFeedback (feedback) {
      this.feedbackToDelete = feedback
      this.deleteDialog = true
    }
  },
  watch: {
    paginationOptions: {
      deep: true,
      handler () {
        this.loadFeedback()
      }
    }
  },
  created () {
    this.getFeedback()
    this.loadFeedback = this.debounce(this.getFeedback, 2000)
  }
}
</script>

<style scoped>

</style>
