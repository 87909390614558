var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Zpětná vazba "),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.feedback,"loading":_vm.runningRequest,"options":_vm.paginationOptions,"server-items-length":_vm.feedbackTotal,"item-key":"id","footer-props":{'items-per-page-options': [10, 20, 50] }},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"item.rating",fn:function(ref){
var item = ref.item;
return [_c('v-rating',{attrs:{"color":"orange","readonly":"","value":item.rating,"half-increments":"","length":"3","size":"22"}})]}},{key:"item.reporter.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reporter.email)+" ")]}},{key:"item.reported.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reported.email)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteFeedback(item)}}},[_vm._v(" mdi-delete ")])]}}])}),_c('v-dialog',{model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[(_vm.deleteDialog)?_c('DeleteResource',{attrs:{"resource":_vm.feedbackToDelete,"resource-type":"admin/feedback"},on:{"delete-success":function($event){_vm.showDeleteSuccess(); _vm.getFeedback()},"delete-failure":_vm.showDeleteFailure}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }